<template>
  <div class="w-full flex items-center justify-center">
    <div class="container">
      <div class="py-4">
        <div v-if="videoIntro.title">
          <h1 class="fs-32 text-center mb-4 font-bold text-pri-900">{{ videoIntro.title }}</h1>
        </div>
        <div v-if="videoIntro.summary" class="mb-4 text-xl font-medium">
          <div v-html="videoIntro.summary"></div>
        </div>
        <div class="mb-4" v-if="videoIntro.description.length">
          <div v-for="(desc, index) in videoIntro.description" :key="index">
            <!-- Paragraphs -->
            <template v-if="desc.paragraphs && desc.paragraphs.length">
              <div v-for="(p, pIndex) in desc.paragraphs" :key="pIndex" v-html="p" />
            </template>

            <!-- Images -->
            <template v-if="desc.images && desc.images.length">
              <template v-for="(img, imgIndex) in desc.images">
                <p v-if="img.src" :key="imgIndex" :class="getAlignment(img.align)">
                  <img :src="img.src" :alt="img.alt || 'Image'" />
                </p>
              </template>
            </template>

            <!-- Videos -->
            <template v-if="desc.videos && desc.videos.length">
              <template v-for="(video, videoIndex) in desc.videos">
                <div class="video-wrapper fs-0 mb-4" v-if="video.url" :key="videoIndex"
                  :set="vInfo = getVideoInfo(video)">
                  <iframe
                    :src="`https://www.youtube.com/embed/${vInfo.watchId}?autoplay=${vInfo.autoplay}&loop=${vInfo.loop}${vInfo.loop ? `&playlist=${vInfo.watchId}` : ``}&mute=${vInfo.mute}&controls=${vInfo.controls}&playsinline=1`"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoIntro',
  data () {
    return {
      videoIntro: {}
    }
  },
  async created () {
    await this.getVideo()
  },
  methods: {
    async getVideo () {
      try {
        const domain = window.location.origin
        const res = await window.fetch(`${domain}/assets/data/video-intro.json`).then(data => data.json())

        this.videoIntro = res
      } catch (error) {
        console.log(error)
      }
    },
    getWatchId (url) {
      const regex = /watch\?v=([^&]+)/
      const match = url.match(regex)
      if (match) {
        const videoId = match[1]
        return videoId
      }
    },
    getVideoInfo (video) {
      if (!video?.url) return {}

      const watchId = this.getWatchId(video.url)
      return {
        ...video,
        watchId
      }
    },
    getAlignment (value) {
      switch (value) {
        case 'left':
          return 'text-left'
        case 'center':
          return 'text-center'
        case 'right':
          return 'text-right'
        case 'justify':
          return 'text-justify'
        default:
          return 'text-left'
      }
    }
  }
}
</script>

<style>
img {
  max-width: 100%;
}

.fs-0 {
  font-size: 0;
}

.video-wrapper {
  aspect-ratio: 16 / 9;
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
}
</style>
